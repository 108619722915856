@keyframes slide-in-bottom {
    0% {
      transform: translateY(200px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  
  }
  
  .x15-child1 {
    position: absolute;
    top: 0;
    left: 0;
    width: 1760px;
    height: 900px;
  }
  .x15-child2 {
    position: absolute;
    height: 22.22%;
    top: 77.78%;
    bottom: 0;
    left: calc(50% - 2105px);
    max-height: 100%;
    width: 4416px;
    display: none;
  }
  .group-child57 {
    position: absolute;
    top: 0;
    left: 0;
    background-color: var(--black);
    width: 342px;
    height: 81px;
  }
  .b324 {
    position: absolute;
    top: 22px;
    left: 78px;
    letter-spacing: 0.11em;
  }
  .rectangle-parent52 {
    position: absolute;
    top: 760px;
    left: 1368px;
    width: 342px;
    height: 81px;
    display: none;
    font-size: 33.32px;
    color: var(--white);
  }
  .span53 {
    color: var(--black);
  }
  .span54 {
    color: var(--color-silver);
  }
  .b325 {
    position: absolute;
    width: 89.03%;
    top: 6.67%;
    left: 3.81%;
    font-size: var(--font-size-29xl);
    line-height: 120%;
    text-transform: uppercase;
    display: inline-block;
    color: var(--blue);
  }
  .b4000,
  .b4001 {
    position: absolute;
    height: 14.89%;
    width: 37.27%;
    top: 28.44%;
    left: 11.76%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .b4001 {
    width: 20.91%;
    left: 64.72%;
  }
  .icon26,
  .x15-child3 {
    position: absolute;
    max-height: 100%;
  }
  .x15-child3 {
    height: 37px;
    top: 7.44%;
    right: 67px;
    bottom: 87.78%;
    width: 37px;
    cursor: pointer;
  }
  .icon26 {
    height: 84.35%;
    width: 64.58%;
    top: 9.38%;
    right: 17.71%;
    bottom: 6.28%;
    left: 17.71%;
    max-width: 100%;
    overflow: hidden;
  }
  .outlineinterfacetrash7 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    height: 6.67%;
    top: 32.67%;
    right: 70px;
    bottom: 60.67%;
    width: 60px;
  }
  .p155 {
    margin: 0;
  }
  .div246,
  .x15-child4 {
    position: absolute;
    left: 3.98%;
  }
  .div246 {
    width: 96.02%;
    top: 52.22%;
    font-size: var(--font-size-5xl);
    line-height: 37px;
    display: inline-block;
  }
  .x15-child4 {
    height: 15%;
    width: 7.67%;
    top: 28.33%;
    right: 88.35%;
    bottom: 56.67%;
    max-width: 100%;
    overflow: hidden;
    max-height: 100%;
    object-fit: cover;
  }
  .b328,
  .group-child58,
  .wrapper61 {
    position: absolute;
    height: 100%;
    top: 0;
  }
  .group-child58 {
    width: 57.25%;
    right: 21.38%;
    bottom: 0;
    left: 21.38%;
    background-color: var(--white);
    border: 3.6px solid var(--black);
    box-sizing: border-box;
  }
  .b328,
  .wrapper61 {
    left: 0;
  }
  .b328 {
    width: 100%;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .wrapper61 {
    width: 22.83%;
    right: 77.17%;
    bottom: 0;
    background-color: var(--black);
  }
  .span55 {
    text-transform: uppercase;
  }
  .txt26 {
    line-break: anywhere;
    width: 100%;
  }
  .div247,
  .wrapper62 {
    position: absolute;
    height: 100%;
    top: 0;
  }
  .div247 {
    width: 50%;
    left: 25%;
    font-size: 27.84px;
    color: var(--black);
    display: flex;
    align-items: center;
  }
  .wrapper62 {
    width: 22.83%;
    right: 0;
    bottom: 0;
    left: 77.17%;
    background-color: var(--black);
  }
  .rectangle-parent53 {
    position: absolute;
    height: 9.44%;
    width: 15.68%;
    top: 31.11%;
    right: 35.28%;
    bottom: 59.44%;
    left: 49.03%;
    display: none;
    text-align: center;
    font-size: 55.68px;
    color: var(--white);
  }
  .b5000,
  .group-child4000 {
    position: absolute;
    width: 100%;
    left: 0;
  }
  .group-child4000 {
    height: 100%;
    top: 0;
    right: 0;
    bottom: 0;
    background-color: var(--black);
  }
  .b5000 {
    height: 96.3%;
    top: 2.47%;
    font-size: 33.32px;
    letter-spacing: 0.11em;
    display: flex;
    font-family: var(--font-pt-mono);
    color: var(--white);
    text-align: center;
    align-items: center;
    justify-content: center;
  }
  .rectangle-parent4000 {
    cursor: pointer;
    border: 0;
    padding: 0;
    background-color: transparent;
    position: absolute;
    height: 35.84%;
    width: 19.43%;
    top: 24.34%;
    right: 76.59%;
    bottom: 39.82%;
    left: 3.98%;
  }
  .b331,
  .div248 {
    position: absolute;
    height: 100%;
    top: 0;
    display: flex;
    align-items: center;
  }
  .div248 {
    width: 28.02%;
    left: 71.98%;
    line-height: 120%;
  }
  .b331 {
    width: 67.66%;
    left: 0;
    font-size: var(--font-size-51xl);
    text-align: right;
    color: black;
  }
  .group-parent4000,
  .parent35 {
    position: absolute;
  }
  .parent35 {
    height: 84.96%;
    width: 63.07%;
    top: 0;
    right: 3.98%;
    bottom: 15.04%;
    left: 32.95%;
  }
  .group-parent4000 {
    height: 25.11%;
    width: 100%;
    top: 78.22%;
    right: 0;
    bottom: -3.33%;
    left: 0;
    background-color: var(--yellow);
    font-size: var(--font-size-13xl);
  }
  .x158 {
    position: relative;
    background-color: var(--white);
    width: 1760px;
    height: 900px;
    overflow: hidden;
    max-width: 100%;
    max-height: 100%;
    text-align: left;
    font-size: var(--font-size-21xl);
    color: var(--black);
    font-family: var(--font-pt-mono);
  }
  
  .x158.animate {
    animation: 0.25s ease 0s 1 normal forwards slide-in-bottom;
  }
  
  @media screen and (max-width: 1330px) {
    .b325 {
      font-size: var(--font-size-21xl);
    }
    .b326,
    .b327 {
      font-size: var(--font-size-13xl);
    }
    .div246 {
      font-size: var(--font-size-lg);
    }
    .b5000,
    .div248 {
      font-size: var(--font-size-5xl);
    }
    .b331 {
      font-size: var(--font-size-41xl);
    }
  }
  @media screen and (max-width: 960px) {
    .b325 {
      font-size: var(--font-size-11xl);
    }
    .b326,
    .b327 {
      font-size: var(--font-size-5xl);
    }
    .div246 {
      font-size: var(--font-size-base);
    }
    .b5000,
    .div248 {
      font-size: var(--font-size-lg);
    }
    .b331 {
      font-size: var(--font-size-31xl);
    }
  }
  