@import url("https://fonts.googleapis.com/css2?family=PT+Mono:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-pt-mono: "PT Mono";
  --font-roboto: Roboto;

  /* font sizes */
  --font-size-9xl: 28px;
  --font-size-xl: 20px;
  --font-size-13xl: 32px;
  --font-size-5xl: 24px;
  --font-size-51xl: 70px;
  --font-size-77xl: 96px;
  --font-size-14xl-3: 33.3px;
  --font-size-7xl-8: 26.8px;
  --font-size-lg: 18px;
  --font-size-base: 16px;
  --font-size-2xl: 21px;
  --font-size-21xl: 40px;
  --font-size-11xl: 30px;
  --font-size-sm: 14px;
  --font-size-8xl: 27px;
  --font-size-base-7: 16.7px;
  --font-size-smi-4: 12.4px;
  --font-size-7xl: 26px;
  --font-size-xs-8: 11.8px;
  --font-size-7xl-7: 26.7px;
  --font-size-sm-9: 13.9px;
  --font-size-17xl: 36px;
  --font-size-31xl: 50px;
  --font-size-29xl: 48px;
  --font-size-41xl: 60px;
  --font-size-36xl-7: 55.7px;
  --font-size-8xl-8: 27.8px;
  --font-size-53xl-9: 72.9px;
  --font-size-mini: 15px;
  --font-size-sm-3: 13.3px;
  --font-size-32xl: 51px;

  /* Colors */
  --white: #fff;
  --yellow: #ffde00;
  --black: #1d1d1b;
  --blue: #009fe3;
  --color-black: #000;
  --color-silver: #bbb;

  /* Gaps */
  --gap-xl: 20px;
  --gap-10xl: 29px;
  --gap-9xl: 28px;
  --gap-mini: 15px;
  --gap-3xs: 10px;
  --gap-sm: 14px;

  /* border radiuses */
  --br-11xl-3: 30.3px;
  --br-35xl: 54px;
}
