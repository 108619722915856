.mob-20x15-childi {
  position: absolute;
  height: 1.08%;
  top: 0.99%;
  right: 20px;
  bottom: 97.93%;
  max-height: 100%;
  width: 15.1px;
  cursor: pointer;
}
.spani {
  color: var(--black);
}
.span1i {
  color: var(--blue);
}
.b15i {
  position: absolute;
  width: 89.6%;
  top: 3.14%;
  left: 5.07%;
  line-height: 120%;
  text-transform: uppercase;
  display: inline-block;
  color: var(--color-silver);
}
.framei,
.group-divi,
.wrapper1i,
.wrapper2i {
  position: absolute;
  height: 1.14%;
  width: 89.33%;
  top: 6.21%;
  right: 5.6%;
  bottom: 92.65%;
  left: 5.07%;
}
.group-divi,
.wrapper1i,
.wrapper2i {
  top: 12.34%;
  right: 5.33%;
  bottom: 86.52%;
  left: 5.33%;
}
.wrapper1i,
.wrapper2i {
  top: 18.47%;
  right: 5.6%;
  bottom: 80.39%;
  left: 5.07%;
}
.wrapper2i {
  top: 24.61%;
  bottom: 74.25%;
}
.emaili {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
  line-height: 100%;
  display: inline-block;
}
.email-wrapperi,
.wrapper3i,
.wrapper4i {
  position: absolute;
  height: 1.14%;
  width: 89.33%;
  top: 30.74%;
  right: 5.6%;
  bottom: 68.12%;
  left: 5.07%;
}
.wrapper3i,
.wrapper4i {
  top: 36.88%;
  bottom: 61.98%;
}
.wrapper4i {
  top: 43.01%;
  bottom: 55.85%;
}
.ii {
  width: 89.33%;
  top: 49.14%;
  line-height: 100%;
  display: inline-block;
}
.b23i,
.ii,
.wrapper5i {
  position: absolute;
  left: 5.07%;
}
.wrapper5i {
  height: 1.14%;
  width: 89.6%;
  top: 73.32%;
  right: 5.33%;
  bottom: 25.53%;
}
.b23i {
  width: 89.33%;
  top: 55.63%;
  line-height: 100%;
  display: inline-block;
}
.mob-20x15-itemi {
  position: absolute;
  top: 770px;
  left: -346px;
  width: 1067px;
  height: 615px;
  display: none;
}
.p22i {
  margin: 0;
  left: 10%;
}
.div45i {
  width: 73.77%;
  top: 0;
  left: 32.23%;
  line-height: 120%;
  display: inline-block;
}
.b24i,
.div45i,
.parenti {
  position: absolute;
}
.b24i {
  width: 25%;
  top: 2.63%;
  left: 0;
  font-size: var(--font-size-13xl);
  display: inline-block;
}
.parenti {
  height: 19.19%;
  width: 65.07%;
  top: 15.66%;
  right: 23.2%;
  bottom: 65.15%;
  left: 11.73%;
}
.b25i,
.group-itemi {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.group-itemi {
  right: 0;
  bottom: 0;
  background-color: var(--black);
}
.b25i {
  font-size: var(--font-size-base);
  letter-spacing: 0.11em;
  text-transform: uppercase;
  display: flex;
  font-family: var(--font-pt-mono);
  color: var(--white);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.rectangle-groupi {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 20.2%;
  width: 77.6%;
  top: 45.45%;
  right: 11.2%;
  bottom: 34.34%;
  left: 11.2%;
}
.group-parenti {
  position: absolute;
  top: 1204px;
  left: 0;
  background-color: var(--yellow);
  width: 100%;
  height: 198px;
}
.inputflushed00,
.inputflushed100,
.inputflushed200,
.inputflushed300,
.inputflushed400 {
  background-color: transparent;
  position: absolute;
  height: 3.02%;
  width: 100%;
  top: 0;
  right: 5.21%;
  bottom: 96.98%;
  left: 0;
}
.inputflushed100,
.inputflushed200,
.inputflushed300,
.inputflushed400 {
  top: 8.13%;
  bottom: 88.85%;
}
.inputflushed200,
.inputflushed300,
.inputflushed400 {
  top: 16.26%;
  bottom: 80.72%;
}
.inputflushed300,
.inputflushed400 {
  top: 24.39%;
  bottom: 72.59%;
}
.inputflushed400 {
  top: 32.51%;
  bottom: 64.46%;
}
.checkboxdefault-with-label00 {
  position: absolute;
  height: 5.48%;
  width: 100%;
  top: 65.22%;
  right: 0;
  bottom: 29.3%;
  left: 0;
}
.checkboxdefault-with-label100,
.checkboxdefault-with-label200,
.inputflushed5 {
  position: absolute;
  height: 5.48%;
  width: 100%;
  top: 71.55%;
  right: 0;
  bottom: 22.97%;
  left: 0;
}
.checkboxdefault-with-label200,
.inputflushed500 {
  top: 77.88%;
  bottom: 16.64%;
}
.inputflushed500 {
  background-color: transparent;
  height: 3.02%;
  width: 100%;
  top: 89.32%;
  right: 5.21%;
  bottom: 7.66%;
  left: 0.01%;
}
.checkboxdefault-with-label300 {
  position: absolute;
  height: 5.48%;
  width: 100%;
  top: 94.52%;
  right: 0;
  bottom: 0;
  left: 0;
}
.inputflushed-parent00 {
  position: absolute;
  height: 75.46%;
  width: 81.87%;
  top: 8.49%;
  right: 12.8%;
  bottom: 16.05%;
  left: 5.33%;
}
.inputflushed600,
.inputflushed700,
.inputflushed800 {
  background-color: transparent;
  position: absolute;
  height: 2.28%;
  width: 100%;
  top: 40.6%;
  right: 17.07%;
  bottom: 58.56%;
  left: 0.01%;
}
.inputflushed700,
.inputflushed800 {
  top: 49%;
  bottom: 52.43%;
}
.inputflushed800 {
  top: 57.43%;
  bottom: 46.29%;
}
.mob-20x15i {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1402px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-pt-mono);
}
