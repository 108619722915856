.a0093-icon,
.eng-mob-end-child {
  position: absolute;
  max-height: 100%;
}
.a0093-icon {
  height: 102.27%;
  width: 325.07%;
  top: 0;
  right: -225.07%;
  bottom: -2.27%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  opacity: 0.6;
}
.eng-mob-end-child {
  height: 1.9%;
  top: 1.76%;
  right: 18.95px;
  bottom: 96.34%;
  width: 15.11px;
  cursor: pointer;
}
.traced-icon {
  position: absolute;
  top: calc(50% - 60.67px);
  left: calc(50% - 112.65px);
  border-radius: 30.26px;
  width: 229.95px;
  height: 122.35px;
}
.im-already {
  margin: 0;
}
.im-already-flying-container1 {
  line-break: anywhere;
  width: 100%;
}
.im-already-flying-container {
  position: absolute;
  top: 0;
  left: 0;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  width: 231px;
  height: 120px;
}
.traced-parent {
  position: absolute;
  top: calc(50% - 253px);
  left: calc(50% - 118.5px);
  width: 231.23px;
  height: 120px;
  text-align: center;
  font-size: var(--font-size-5xl);
}
.for-further-information-container,
.your-order-has {
  position: absolute;
  width: 76.53%;
  left: 12.27%;
  line-height: 130%;
  display: inline-block;
}
.your-order-has {
  top: 38.54%;
}
.for-further-information-container {
  top: 47.36%;
}
.close,
.group-child60 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.group-child60 {
  height: 100%;
  right: 0;
  bottom: 0;
  background-color: var(--black);
}
.close {
  height: 97.5%;
  font-size: var(--font-size-base);
  letter-spacing: 0.11em;
  display: flex;
  font-family: var(--font-pt-mono);
  color: var(--white);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.rectangle-parent45 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 5.04%;
  width: 77.6%;
  top: 61.46%;
  right: 11.2%;
  bottom: 33.5%;
  left: 11.2%;
}
.eng-mob-end {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 794px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--black);
  font-family: var(--font-pt-mono);
}
