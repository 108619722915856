.mob-20x15-child {
  position: absolute;
  top: 1.99%;
  right: 19.95px;
  width: 15.11px;
  height: 15.11px;
  cursor: pointer;
}
.span43 {
  color: var(--black);
}
.span44 {
  color: var(--blue);
}
.b274,
.b275 {
  position: absolute;
  display: inline-block;
}
.b274 {
  width: 89.6%;
  top: 4.24%;
  left: 5.07%;
  line-height: 120%;
  text-transform: uppercase;
  color: var(--color-silver);
}
.b275 {
  width: 100%;
  top: 0;
  left: 0;
  line-height: 100%;
}
.wrapper39,
.wrapper40 {
  position: absolute;
  height: 1.54%;
  width: 89.33%;
  top: 8.38%;
  right: 5.6%;
  bottom: 90.08%;
  left: 5.07%;
}
.wrapper40 {
  top: 16.67%;
  right: 5.33%;
  bottom: 81.79%;
  left: 5.33%;
}
.wrapper41,
.wrapper42 {
  height: 1.54%;
  width: 89.33%;
  right: 5.6%;
}
.wrapper41 {
  position: absolute;
  top: 24.95%;
  bottom: 73.51%;
  left: 5.07%;
}
.wrapper42 {
  top: 33.24%;
  bottom: 65.22%;
}
.b280,
.wrapper42,
.wrapper43 {
  position: absolute;
  left: 5.07%;
}
.wrapper43 {
  height: 1.54%;
  width: 89.6%;
  top: 65.41%;
  right: 5.33%;
  bottom: 33.04%;
}
.b280 {
  width: 89.33%;
  top: 41.52%;
  line-height: 100%;
  display: inline-block;
}
.mob-20x15-item {
  position: absolute;
  top: 770px;
  left: -346px;
  width: 1067px;
  height: 615px;
  display: none;
}
.wgroup-parent24 {
  position: absolute;
  top: 850px;
  left: 0;
  background-color: var(--yellow);
  width: 100%;
  height: 198px;
}
.p140 {
  margin: 0;
}
.div235 {
  width: 73.77%;
  top: 0;
  left: 30%;
  line-height: 120%;
  display: inline-block;
}
.b281,
.div235,
.parent24 {
  position: absolute;
}
.b281 {
  width: 40%;
  top: 2.63%;
  left: 0;
  font-size: var(--font-size-13xl);
  display: inline-block;
}
.parent24 {
  height: 19.19%;
  width: 65.07%;
  top: 15.66%;
  right: 23.2%;
  bottom: 65.15%;
  left: 11.73%;
}
.b282,
.group-child61 {
  position: absolute;
  height: 100%;
  width: 100%;
  top: 0;
  left: 0;
}
.group-child61 {
  right: 0;
  bottom: 0;
  background-color: var(--black);
}
.b282 {
  font-size: var(--font-size-base);
  letter-spacing: 0.11em;
  text-transform: uppercase;
  display: flex;
  font-family: var(--font-pt-mono);
  color: var(--white);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.rectangle-parent46 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 20.2%;
  width: 77.6%;
  top: 45.45%;
  right: 11.2%;
  bottom: 34.34%;
  left: 11.2%;
}
.group-parent24 {
  position: absolute;
  top: 850px;
  left: 0;
  background-color: var(--yellow);
  width: 100%;
  height: 198px;
}
.inputflushed35 {
  top: 0;
  bottom: 95.49%;
}
.inputflushed35,
.inputflushed36,
.inputflushed37,
.inputflushed38 {
  background-color: transparent;
  position: absolute;
  height: 4.51%;
  width: 94.79%;
  right: 5.21%;
  left: 0;
}
.inputflushed36 {
  top: 12.13%;
  bottom: 83.36%;
}
.inputflushed37 {
  top: 24.26%;
  bottom: 71.23%;
}
.inputflushed38 {
  top: 36.39%;
  bottom: 59.1%;
}
.checkboxdefault-with-label28 {
  position: absolute;
  height: 8.18%;
  width: 100%;
  top: 48.1%;
  right: 0;
  bottom: 43.72%;
  left: 0;
}
.checkboxdefault-with-label29 {
  top: 57.55%;
  bottom: 34.27%;
}
.checkboxdefault-with-label29,
.checkboxdefault-with-label30 {
  position: absolute;
  height: 8.18%;
  width: 100%;
  right: 0;
  left: 0;
}
.inputflushed39 {
  position: absolute;
  left: 0;
}
.checkboxdefault-with-label30 {
  top: 67%;
  bottom: 24.82%;
}
.inputflushed39 {
  background-color: transparent;
  height: 4.51%;
  width: 94.79%;
  top: 84.06%;
  right: 5.21%;
  bottom: 11.42%;
}
.checkboxdefault-with-label31 {
  position: absolute;
  height: 8.18%;
  width: 100%;
  top: 91.82%;
  right: 0;
  bottom: 0;
  left: 0;
}
.inputflushed-parent4 {
  position: absolute;
  height: 68.3%;
  width: 81.87%;
  top: 11.46%;
  right: 12.8%;
  bottom: 20.23%;
  left: 5.33%;
}
.mob-20x15 {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 1038px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--black);
  font-family: var(--font-pt-mono);
}
