.a0093-icon2,
.mob-end-child {
  position: absolute;
  max-height: 100%;
}
.a0093-icon2 {
  height: 102.27%;
  width: 325.07%;
  top: 0;
  right: -225.07%;
  bottom: -2.27%;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  object-fit: cover;
  opacity: 0.6;
}
.mob-end-child {
  height: 1.9%;
  top: 1.76%;
  right: 18.95px;
  bottom: 96.34%;
  width: 15.11px;
  cursor: pointer;
}
.traced-icon2 {
  position: absolute;
  top: calc(50% - 60.67px);
  left: calc(50% - 112.65px);
  border-radius: 30.26px;
  width: 229.95px;
  height: 122.35px;
}
.b335,
.traced-container {
  position: absolute;
  height: 120px;
}
.b335 {
  top: 0;
  left: 0;
  line-height: 120%;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 231px;
}
.traced-container {
  top: calc(50% - 283px);
  left: calc(50% - 118.5px);
  width: 231.23px;
  text-align: center;
  font-size: var(--font-size-5xl);
}
.div260 {
  position: absolute;
  width: 76.53%;
  top: 38.54%;
  left: 12.27%;
  line-height: 130%;
  display: inline-block;
}
.div260i {
  position: absolute;
  width: 96.53%;
  top: 33.00%;
  left: 6.54%;
  line-height: 130%;
  display: inline-block;
}
.p166 {
  margin: 0;
}
.span63 {
  font-family: var(--font-pt-mono);
}
.div261 {
  position: absolute;
  width: 76.53%;
  top: 47.21%;
  left: 12.27%;
  line-height: 130%;
  white-space: pre-wrap;
  display: inline-block;
}
.b336,
.group-child80 {
  position: absolute;
  width: 100%;
  top: 0;
  left: 0;
}
.group-child80 {
  height: 100%;
  right: 0;
  bottom: 0;
  background-color: var(--black);
}
.b336 {
  height: 97.5%;
  font-size: var(--font-size-base);
  letter-spacing: 0.11em;
  display: flex;
  font-family: var(--font-pt-mono);
  color: var(--white);
  text-align: center;
  align-items: center;
  justify-content: center;
}
.rectangle-parent63 {
  cursor: pointer;
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  height: 5.04%;
  width: 77.6%;
  top: 57.68%;
  right: 11.2%;
  bottom: 37.28%;
  left: 11.2%;
}
.mob-end {
  position: relative;
  background-color: var(--white);
  width: 100%;
  height: 794px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-mini);
  color: var(--black);
  font-family: var(--font-pt-mono);
}
